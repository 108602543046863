import Vue from 'vue';
import VueResource from "vue-resource";
import config from '@/config';

Vue.use(VueResource);

config.setapikey();

export const corporateService = {
  get,
  list,
  listEventsByCorporateId,
  base64,
  save,
  changeCorporateExclusive,
  saveExclusiveCompanies,
  listLogs
}

function saveExclusiveCompanies(loggedId, eventId, companyIds){
    const url = `${config.api}/v1/admin/corporate/exclusive/saveMany`;

    const body = {
        logged_id: loggedId,
        id_evento: eventId,
        company_ids: companyIds
    }

    return new Promise((resolve, reject) => {
            Vue.http
                .post(url, body,  { emulateJSON: true })
                .then(res => resolve(res.body))
                .catch(err =>  reject({ error: true, msg: err }))
        }
    );
}

function listLogs({ loggedId, currentPage, perPage, dateStart, dateEnd, sortColumn, sortDirection }) {
  let url = config.api + `/v1/admin/corporate/listLogs?loggedId=${loggedId}&dtInicial=${dateStart}&dtFinal=${dateEnd}&sortColumn=${sortColumn}&sortDirection=${sortDirection}`;
  url = config.system.applyPagination(url, currentPage, perPage);

  const options = {
    responseType: 'json',
  };

  return Vue.http.get(url, options)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.error('Error downloading XLSX file or fetching data:', error);
      throw error;
    });
}

  

function list(search, currentPage, perPage, idEvent, showInactive = false, filterFilial = false) {
  let url = config.api + `/v1/admin/corporate/list?search=${search}`;
  if (idEvent) {
    url += `&idEvent=${idEvent}`
  }

  // Adicionando o parâmetro para mostrar empresas inativas
  if (showInactive) {
    url += `&status=Inativo`;
  }

  // Adicionando o parâmetro para filtrar por filial
  if (filterFilial) {
    url += `&filterFilial=1`;
  }

  url = config.system.applyPagination(url, currentPage, perPage);

  var ret = new Promise(
    function (resolve, reject) {
      Vue.http.get(url).then(res => {
        resolve(res.body);
      }, err => {
        reject({
          error: true,
          msg: err
        });
      });
    }
  );
  return ret;
}

function listEventsByCorporateId(id_corporativo, currentPage, perPage) {
    let url = config.api + `/v1/admin/corporate/exclusive/list_events?id_corporativo=${id_corporativo}`;

    url = config.system.applyPagination(url, currentPage, perPage);

    var ret = new Promise(
    function (resolve, reject) {
        Vue.http.get(url).then(res => {
        resolve(res.body);
        }, err => {
        reject({
            error: true,
            msg: err
        });
        });
    }
  );
  return ret;
}

async function changeCorporateExclusive(action, loggedId, idCorporativo, idEvento) {
    let url = config.api + `/v1/admin/corporate/exclusive/save`;
    if (action === 'delete') {
        url = config.api + `/v1/admin/corporate/exclusive/delete`;
    }
    let obj = { loggedId, idCorporativo, idEvento };
    return await Vue.http.post(url, obj, { emulateJSON: true }).then(response => response.data)
    .catch(error => []);
}

function get(id) {
    let url = config.api + `/v1/admin/corporate/get?id=${id}`;
    
    var ret = new Promise(
    function (resolve, reject) {
        Vue.http.get(url).then(res => {
        resolve(res.body);
        }, err => {
        reject({
            error: true,
            msg: err
        });
        });
    }
  );
  return ret;
}
function save(loggedId, id, name, cnpj, url_company, code, changedcode, saveimage, image, messagepromo, qtde_func, status, require_login) {
    let url = config.api + `/v1/admin/corporate/save`;

    let obj = {
        loggedId,
        id: id != undefined && id != null ? id : '',
        name,
        cnpj,
        url: url_company,
        code,
        changedcode: changedcode ? 1 : 0,
        saveimage,
        image,
        messagepromo,
        qtde_func,
        status,
        require_login
    };

    var ret = new Promise(
        function (resolve, reject) {
            Vue.http.post(url, obj, { emulateJSON: true }).then(res => {
                resolve(res.body);
            }, err => {
                reject({
                    error: true,
                    msg: err
                });
            });    
        }
    );
    return ret;
}

function base64(id, type) {
    let url = config.api + `/v1/admin/corporate/base64`;

    let obj = { id, type };

    var ret = new Promise(
        function (resolve, reject) {
            Vue.http.post(url, obj, { emulateJSON: true }).then(res => {
                resolve(res.body);
            }, err => {
                reject({
                    error: true,
                    msg: err
                });
            });
        }
    );
    return ret;
}
